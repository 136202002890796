import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';

const DefaultPageTemplate = ({
  data: {
    contentfulSiteData: { siteName },
    contentfulPage: { title, content, metaDescription },
  },
}) => (
  <Layout title={title} description={metaDescription} siteName={siteName}>
    <main>
      <PageContent title={title} richText={content} />
    </main>
  </Layout>
);

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    contentfulSiteData {
      siteName
    }
    contentfulPage(id: { eq: $id }) {
      title
      content {
        raw
        references {
          ...ContentFragment
        }
      }
      slug
      metaDescription
    }
  }
`;

export default DefaultPageTemplate;
