import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, HtmlContent, Link } from './ui';

const StyledPageContent = styled.section`
  ${sectionMargins('50px', '100px')};
`;

const StyledHeading = styled.h1`
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: ${fontWeights.regular};
  ${fluidFontSize(
    '32px',
    '42px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};
  line-height: 1.2;

  ${minBreakpointQuery.medium`
    text-align: center;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledImageOuter = styled.div`
  ${sectionMargins('30px', '50px')};
`;

const StyledImageInner = styled.div``;

const StyledImage = styled.figure`
  margin: 0;
`;

const StyledCaption = styled.figcaption`
  margin-top: 10px;
  ${fontSize(14)};
  line-height: 1.6;
  font-weight: ${fontWeights.bold};
  text-align: left;

  ${minBreakpointQuery.small`
    ${fontSize(15)};
  `}
`;

const StyledHtmlContent = styled(HtmlContent)`
  ${minBreakpointQuery.medium`
    ul,ol {
      text-align: left;
    }
  `}

  blockquote {
    font-weight: ${fontWeights.bold};
    ${fluidFontSize(
      '32px',
      '42px',
      breakpointSizes.tiny,
      breakpointSizes.xxlarge
    )};

    p {
      padding: 0;
      font-size: inherit;
    }
  }
`;

const PageContent = ({ title, richText }) => {
  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const { gatsbyImageData, title, description } = node.data.target;
        if (gatsbyImageData) {
          // asset is not an image

          return null;
        }

        return (
          <StyledImageOuter>
            <StyledImageInner>
              <StyledImage>
                <GatsbyImage image={gatsbyImageData} alt={title} />
                {description && <StyledCaption>{description}</StyledCaption>}
              </StyledImage>
            </StyledImageInner>
          </StyledImageOuter>
        );
      },
      'entry-hyperlink': node => {
        const { slug } = node.data.target;
        if (!slug) {
          return null;
        }

        return <Link to={slug}>{node.content[0].value}</Link>;
      },
      'asset-hyperlink': node => {
        const { url } = node.data.target;
        if (!url) {
          return null;
        }
        return (
          <Link href={url} target="_blank" rel="noopener">
            {node.content[0].value}
          </Link>
        );
      },
    },
  };

  return (
    <Container>
      <StyledPageContent>
        <StyledHeading>{title}</StyledHeading>
        <StyledHtmlContent richText={richText} options={options} />
      </StyledPageContent>
    </Container>
  );
};

export default PageContent;

export const ContentFragment = graphql`
  fragment ContentFragment on Node {
    ... on ContentfulAsset {
      __typename
      contentful_id
      gatsbyImageData(width: 1100)
      title
      description
      url
    }
    ... on ContentfulAccount {
      contentful_id
      __typename
      slug
    }
    ... on ContentfulPage {
      contentful_id
      __typename
      slug
    }
  }
`;
